@media screen and (max-width: 420px) {
  #features {
    width: 100%;
  }

  #navigation {
    width: 100%;
  }

  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer,
  #portfolio,
  #header {
    width: 100%;
  }

  .image-container {
    width: 104%;
  }

  #email.form-control,
  #name.form-control,
  #contactNumber.form-control {
    width: 100%;
  }
  body {
    zoom: 90%;
  }
}
